import localFont from 'next/font/local';
import Head from 'next/head';

export const lexia = localFont({
    src: [
        {
            path: '../../fonts/lexia/lexia_bd-webfont.woff2',
            weight: '600',
            style: 'normal',
        },
        {
            path: '../../fonts/lexia/lexia_rg-webfont.woff2',
            weight: '400',
            style: 'normal',
        },
    ],
    display: 'swap',
});

export const Lexia = () => (
    <Head>
        <style
            key="fontfaces-lexia"
            className={lexia.className}
            dangerouslySetInnerHTML={{
                __html: `
                :root {
                    --font-family-lexia: Lexia, ${lexia.style.fontFamily}, Helvetica, Arial, sans-serif;
                }`,
            }}
        />
    </Head>
);
